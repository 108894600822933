import './App.css';
import { logo, uoj } from './Assets/index';
import FormCard from './Components/FormCard/FormCard';
import ProgressBar from './Components/ProgressBar/ProgressBar';
import Results from './Components/Results/Results';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useEffect } from 'react';

import { useProgressContext } from './ContextProvider/ProgressContext';

function App() {
  
  

  return (
    <div className='App bg-[#E1E6FA] h-full '>
      <div className='mx-auto flex justify-center sm:p-5  p-2 shadow-lg bg-blue-500 '>
        <img
          className=' w-[700px]  p-2 px-20  rounded-lg bg-white shadow-lg '
          src={logo}
          alt='logo'
        />
      </div>
      
      <div
  className="m-2 rounded-lg shadow-lg"
  style={{
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${uoj})`,
    backgroundSize: 'cover',
  }}
>

        <div className='p-2 sm:p-2 '>
          
          <Router>
      <Routes>
        
      </Routes>
    </Router>
    <Router>
      <Routes>
        <Route path="/:status" element={<Results />} />
        <Route path="/" element={<FormCard  />} />
        
      </Routes>

    </Router>
        </div>
      </div>
      <div className='bg-white p-5 font-Agbalumo'>
        <h1>
          Copyright © 2023 - UOJ Payment | Speed IT Net - All Rights Reserved
        </h1>
      </div>
    </div>
  );
}

export default App;

import React, { useEffect, useRef, useState } from 'react';
import { useProgressContext } from '../../ContextProvider/ProgressContext';
import uojApi from '../../Common/Api/uojApi';
import 'remixicon/fonts/remixicon.css';
import ProgressBar from '../ProgressBar/ProgressBar';



const FormCard = () => {
  const { setActiveState, getActiveState } = useProgressContext();

 

  const [formData, setFormData] = useState({
    name: '',
    voucherCode: '',
    identityNumber: '',
    documentType: 'Passport/NIC',

    payamount: null,
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [user, setUser] = useState({
    name: '',
    purpose: '',
    total: '',
  });

  const [isVoucherValid, setIsVoucherValid] = useState(true); //form validation
  const [authvoucher, setAuthVoucher] = useState(false); //after verified voucher state
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const active = getActiveState();
 
  const [aid, setAid] = useState(active.id);
  const [errors, setErrors] = useState({});
  
  useEffect(() => {
    setAid(active.id);
   
  }, [active]);

  useEffect(() => {
  setActiveState(1); // This will override the reset
}, []);

 


  const payamountRef = useRef();

  // post api call for 1 st stage
 const checkvoucher = async (vouchercode) => {
  try {
    const intvoucher = parseInt(vouchercode);
    const response = await uojApi.post(
      `/voucher-verify?voucher_code=${intvoucher}`
    );
    
    // Check if the response indicates a valid voucher
    if (response.data.message === "This is a valid voucher code") {
      setAuthVoucher(true);
      setError(null);
      setSuccess(response.data.message);
      setUser({
        name: response.data.data.user_name,
        purpose: response.data.data.voucher,
        total: response.data.data.amount,
      });
    } else {
      // Handle case where voucher is not valid but request was successful
      setError(response.data.message);
      setSuccess(null);
      setAuthVoucher(false);
    }
  } catch (error) {
    setError(error.response?.data?.message || "Error verifying voucher");
    setSuccess(null);
    setAuthVoucher(false);
    console.error('Error while verifying voucher', error);

    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }
  }
};

  

 const handleChange = (e) => {
  const { name, value } = e.target;

  if (name === "payamount") {
    // Allow only positive integers, prevent leading zeros
    if (value && (!/^\d+$/.test(value) || /^0\d+/.test(value))) {
      return; // Ignore invalid input
    }

    // Validate only if user.total is defined
    if (user?.total && parseFloat(value) > user.total) {
      setErrors({
        ...errors,
        payamount: `Pay amount cannot exceed Total Amount (${user.total}).`,
      });
      return;
    }

    // Clear errors if input is valid
    setErrors({ ...errors, payamount: "" });
  }

  setFormData({ ...formData, [name]: value });
  setIsDropdownOpen(false);
};


  const handleVerify = () => {
  // Combine voucherPart1 and voucherPart2 to form the full voucher code
  const voucherCode = `${formData.voucherPart1}${formData.voucherPart2}`;

  // Check if the combined voucher code is 16 digits and numeric
  const isNumeric = /^[0-9]+$/.test(voucherCode);
  if (voucherCode.length === 16 && isNumeric) {
    setIsVoucherValid(true);
    checkvoucher(voucherCode); // Pass the full voucher code to the checkvoucher function
  } else {
    setIsVoucherValid(false);
  }
};

  const handleClear = () => {
    setFormData({
      name: '',
      voucherCode: '',
      identityNumber: '',
      documentType: 'Passport/NIC',
      payamount: null, 
    });
    setSuccess(null);
    setError(null);
    setIsVoucherValid(true);
    setAuthVoucher(false);
  };



  const handleBack = () => {
    const activeState = getActiveState();
    if (activeState && activeState.id < 3) {
      setActiveState(activeState.id - 1);
    }
  };
 


const [payNowClicked, setPayNowClicked] = useState(false); // Track Pay Now button click

  // Function to fetch the payment form using uojApi
  useEffect(() => {
    const fetchPaymentForm = async () => {
      try {
        console.log("Sending payment data:", formData);

        // Sending POST request with payment data using uojApi
        const response = await uojApi.post(
          "/payment", // API endpoint for payment
          {
            amount: formData.payamount,
            identity_number: formData.identityNumber,
            identity_type: formData.documentType,
            payee_name: formData.name,
            voucher_code: `${formData.voucherPart1}${formData.voucherPart2}`,
          }
        );

        // Check if the response data is valid and contains a form
        if (response.data && response.data.data) {
          const formHTML = response.data.data.form; // Get the HTML form as a string
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = formHTML; // Set the HTML content inside a temporary div

          // Extract the form element from the HTML string
          const form = tempDiv.querySelector("form");
          if (form) {
            // Append form to the body and submit it
            document.body.appendChild(form);
            form.submit(); // Automatically submit the form
          } else {
            console.error("Form element not found in the response.");
          }
        }

        
   
    
         
      } catch (error) {
        // Handle errors and display an alert
        console.error("Error fetching payment form:", error);
        alert("Error fetching the payment form.");
      }
    };

   

    // Ensure fetchPaymentForm is called only when Pay Now is clicked and formData is valid
    if (payNowClicked) {
      if (
        formData.payamount &&
        formData.identityNumber &&
        formData.documentType &&
        formData.name &&
        `${formData.voucherPart1}${formData.voucherPart2}`
      ) {
        fetchPaymentForm();
      } else {
        alert("Please fill in all required fields.");
      }
      setPayNowClicked(false); // Reset the state to prevent repeated API calls
    }

  
  }, [payNowClicked, formData]); // Runs when payNowClicked or formData changes

   

  // Handle the "Pay Now" button click
  const handlePayNowClick = () => {
    setPayNowClicked(true); 
   
    
   
  };

  const handleNext = () => {
  const handleValidation = () => {
    let isValid = true;
    let errorMsg = {};

    // Validate Voucher Code
     // Validate Voucher Code (Part 1 and Part 2)
  if (!formData.voucherPart1 || formData.voucherPart1.length !== 8) {
    isValid = false;
    errorMsg.voucherPart1 = "Voucher part 1 must be an 8-digit code.";
  }
  if (!formData.voucherPart2 || formData.voucherPart2.length !== 8) {
    isValid = false;
    errorMsg.voucherPart2 = "Voucher part 2 must be an 8-digit code.";
  }

    // Validate Name
    if (!formData.name.trim()) {
      isValid = false;
      errorMsg.name = "Name is required.";
    }

    // Validate Document Type
    if (!formData.documentType) {
      isValid = false;
      errorMsg.documentType = "Identity type is required.";
    }

    // Validate Identity Number
    if (formData.documentType === "NIC") {
      const nicRegexOld = /^[0-9]{9}[vVxX]$/; // e.g., 123456789V
      const nicRegexNew = /^[0-9]{12}$/; // e.g., 200123456789
      if (
        !formData.identityNumber.match(nicRegexOld) &&
        !formData.identityNumber.match(nicRegexNew)
      ) {
        isValid = false;
        errorMsg.identityNumber = "Invalid NIC format.";
      }
    } else if (formData.documentType === "Passport") {
      const passportRegex = /^[A-Z0-9]{7,10}$/; // e.g., AB1234567
      if (!formData.identityNumber.match(passportRegex)) {
        isValid = false;
        errorMsg.identityNumber = "Invalid Passport format.";
      }
    } else {
      isValid = false;
      errorMsg.identityNumber = "Identity type is required.";
    }

    // Validate Pay Amount
    if (!formData.payamount || !/^\d+$/.test(formData.payamount)) {
      isValid = false;
      errorMsg.payamount = "Please enter a valid Pay Amount.";
    }

    setErrors(errorMsg);
    return isValid;
  };

  if (handleValidation()) {
    console.log("All fields are valid. Proceeding to next stage...");
    const activeState = getActiveState();
   
    if (activeState && activeState.id < 3) {
      setActiveState(activeState.id + 1);
    }
  } else {
    console.log("Validation failed. Please check the errors.");
  }
};

  
  
  

  return (
    <div className='flex  items-center justify-center my-12   '>
      <div className='bg-white text-sm lg:text-[16px] text-black rounded-md   border-2 border-gray-100  shadow-lg p-4 md:p-6 md:w-[50%]'>
        <h1 className='mb-0 text-xl lg:text-[22px] text-blue-900 font-semibold tracking-widest uppercase' style={{ textShadow: '' }}>Pay Online</h1>
        <ProgressBar/>

        {/* 1st stage */}
        {aid === 1 && (
          <>
            <div className='sm:mb-8  mb-6'>
              <div className='sm:mb-8 mb-6 '>
                
                <div className='flex  gap-2'>
                  <input
                      className="w-1/2 px-3 py-2 border rounded-l"
                      type="text"
                      id="voucherPart1"
                      name="voucherPart1"
                      placeholder="⁠8 digit pin code"
                      value={formData.voucherPart1}
                      onChange={handleChange}
                      maxLength={8}
                      required
                    />
                    <input
                      className="w-1/2 px-3 py-2 border"
                      type="text"
                      id="voucherPart2"
                      name="voucherPart2"
                      placeholder="8 digit payment code"
                      value={formData.voucherPart2}
                      onChange={handleChange}
                      maxLength={8}
                      required
                    />
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded"
                      onClick={handleVerify}
                    >
                      Verify
                    </button>
                </div>
                {!isVoucherValid && (
                  <p className='text-red-500'>
                    Voucher code must be a 16-digit number.
                  </p>
                )}
                {error && <p className='text-red-500'>{error}</p>}
                {authvoucher && success && (
                 <div className="flex flex-col items-start sm:p-6 p-2 bg-blue-500 bg-opacity-10 shadow-xl rounded-lg w-full lg:w-4/5 sm:mx-auto mx-1 mt-4 sm:mt-4">
                    <h1 className="sm:text-[16px] text-[14px] font-semibold text-gray-700 mb-2 tracking-wide">Student Details</h1>

                      <div className="space-y-1 w-full">
                        <div className="sm:flex sm:justify-between text-start lg:text-[16px] text-[14px]  selection: text-gray-700">
                          <span>User Name:</span>
                          <span className="text-blue-600 font-normal">{user.name}</span>
                        </div>

                        <div className="sm:flex sm:justify-between text-start lg:text-[16px] text-[14px]  text-gray-700">
                          <span>Fee Type: </span>
                          <span className="text-blue-600 font-normal">{user.purpose}</span>
                        </div>

                        <div className="sm:flex sm:justify-between text-start lg:text-[16px] text-[14px]  text-gray-700">
                          <span>Total Amount: </span>
                          <span className="text-blue-600 font-normal">{user.total}</span>
                        </div>
                      </div>
                    </div>
              )}
              </div>

              
              


              <div>
               <input
                className="w-full px-3 py-2 border rounded"
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                required
               />
              {errors.name && <p className="text-red-500 mt-1">{errors.name}</p>}
              </div>

            </div>
              <div className='sm:mb-8 mb-6'>
                <div className='flex sm:gap-2'>
                  {/* Document Type Dropdown */}
                  <div className="relative bg-blue-500 bg-opacity-10 rounded w-fit">
                    <div
                      className="px-3 py-2 border rounded cursor-pointer text-left w-fit"
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      {formData.documentType || "Select Document Type"}
                    </div>
                    {isDropdownOpen && (
                      <div className="absolute left-0 bg-blue-200 border rounded shadow-md w-fit z-10">
                        <div
                          className="p-2 cursor-pointer hover:bg-blue-300"
                          onClick={() =>
                            handleChange({
                              target: { name: "documentType", value: "NIC" },
                            })
                          }
                        >
                          NIC
                        </div>
                        <div
                          className="p-2 cursor-pointer hover:bg-blue-300"
                          onClick={() =>
                            handleChange({
                              target: { name: "documentType", value: "Passport" },
                            })
                          }
                        >
                          Passport
                        </div>
                      </div>
                    )}
                    {errors.documentType && (
                      <p className="text-red-500 mt-1">{errors.documentType}</p>
                    )}
                  </div>

                  {/* Identity Number Input */}
                  <div className="flex-grow">
                    <input
                      className="w-full px-3 py-2 border rounded"
                      type="text"
                      id="identityNumber"
                      name="identityNumber"
                      placeholder="Identity Number"
                      value={formData.identityNumber}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                {/* Error for Identity Number */}
                {errors.identityNumber && (
                  <p className="text-red-500 mt-1">{errors.identityNumber}</p>
                )}
              </div>




            <div className="sm:mb-8 mb-6">
             <input
              className="w-full px-3 py-2 border rounded"
              ref={payamountRef}
              type="text" // Keep it as text to allow better control of input
              id="payamount"
              name="payamount"
              placeholder="Pay Amount"
               value={formData.payamount || ''} 
              onChange={handleChange}
              required
            />
          {errors.payamount && <p className="text-red-500 mt-1">{errors.payamount}</p>}
            

         </div>
            <div className='flex justify-between'>
              <button
                className='bg-red-500 text-white px-4 py-2 rounded shadow-lg'
                onClick={handleClear}
              >
                Clear
              </button>
              <button
                className={`bg-blue-500 text-white px-4 py-2 rounded shadow-xl ${
                  !authvoucher ? 'bg-gray-200' : null
                }`}
                disabled={!authvoucher}
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          </>
        )}
        {/* end of first stage */}

        

        {/* end of 2nd stage */}

        {/* 3rd Stage */}
        {aid === 2 && (
          <>
            <div className='sm:mb-8 mb-6'>
            {/* Student Details Section */}
            <div className='mx-1 md:mx-8'>
            <div className='flex flex-col items-start shadow-lg p-4 bg-blue-500 bg-opacity-10 rounded-lg'>
            <h1 className='font-bold text-gray-700 lg:text-[17px] text-[14px] tracking-wide mb-4'>Student Details</h1>

            <div className='space-y-1 w-full'>
              <div className='sm:flex sm:justify-between text-start lg:text-[16px] text-[14px]  text-gray-700'>
                <span>User Name: </span>
                <span className='text-blue-600 font-normal'>{user.name}</span>
              </div>

              <div className='sm:flex sm:justify-between text-start lg:text-[16px] text-[14px]  text-gray-700'>
                <span>Fee Type: </span>
                <span className='text-blue-600 font-normal'>{user.purpose}</span>
              </div>

              <div className='sm:flex sm:justify-between text-start lg:text-[16px] text-[14px]  text-gray-700'>
                <span>Total Amount: </span>
                <span className='text-blue-600 font-normal'>{user.total}</span>
              </div>

              <div className='sm:flex sm:justify-between text-start lg:text-[16px] text-[14px]  text-gray-700'>
                <span>Voucher Code: </span>
                <span className='text-blue-600 font-normal'>{`${formData.voucherPart1}${formData.voucherPart2}`}</span>
              </div>
            </div>
          </div>

          {/* Payer Details Section */}
          <div className='flex flex-col items-start sm:mt-8 mt-6 shadow-lg p-4 bg-blue-500 bg-opacity-10  rounded-lg'>
            <h1 className='font-bold text-gray-700 lg:text-[17px] text-[14px] tracking-wide mb-4'>Payer Details</h1>

            <div className='space-y-1 w-full'>
              <div className='sm:flex sm:justify-between text-start lg:text-[16px] text-[14px]   text-gray-700'>
                <span>Name: </span>
                <span className='text-blue-600 font-normal'>{formData.name}</span>
              </div>

              <div className='sm:flex sm:justify-between text-start lg:text-[16px] text-[14px]  text-gray-700'>
                <span>{formData.documentType}: </span>
                <span className='text-blue-600 font-normal'>{formData.identityNumber}</span>
              </div>

              <div className='sm:flex sm:justify-between text-start lg:text-[16px] text-[14px]  text-gray-700'>
                <span>Paying Amount: </span>
                <span className='text-blue-600 font-normal'>{parseFloat(formData.payamount).toFixed(2)}</span>
              </div>
            </div>
          </div>
        </div>
        </div>

            <div className='flex justify-between'>
              <button
                className='bg-red-500 text-white px-4 py-2 rounded shadow-lg'
                onClick={handleBack}
              >
                Back
              </button>
              <button
                className='bg-blue-500 text-white px-4 py-2 rounded shadow-xl'
                onClick={handlePayNowClick}
              >
                Pay Now
              </button>
            </div>
          </>
        )}
       

        {/* 4rd Stage */}
        {aid === 3 && (
          <>
        
          <h1>Redirecting to Payment Gateway...</h1>
        
        </>
        )}
        

      
    

      </div>
    </div>
  );
};

export default FormCard;
